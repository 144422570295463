@font-face {
font-family: 'pwcHelveticaNeue';
src: url(/_next/static/media/51b744f8bd5cb383-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'pwcHelveticaNeue';
src: url(/_next/static/media/73a1b9de1a476dc8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'pwcHelveticaNeue';
src: url(/_next/static/media/7e9926f7032915d8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'pwcHelveticaNeue Fallback';src: local("Arial");ascent-override: 95.98%;descent-override: 21.11%;line-gap-override: 0.00%;size-adjust: 101.37%
}.__className_4d0c1b {font-family: 'pwcHelveticaNeue', 'pwcHelveticaNeue Fallback'
}

